export default [
  {
    title: 'Panel',
    icon: 'HomeIcon',
    route: 'dashboard',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  {
    title: 'Kullanıcı',
    icon: 'UserIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'user-list',
      },
    ],
  },

  {
    title: 'Alış Faturaları',
    icon: 'BoxIcon',
    route: 'p-products-list',
    resource: 'm_import',
    action: 'read',
  },

  {
    title: 'Genel Yönetim Giderleri',
    icon: 'BoxIcon',
    route: 'general-expense-list',
    resource: 'm_product',
    action: 'read',
  },
  {
    title: 'Pazarlama Giderleri',
    icon: 'BoxIcon',
    route: 'marketing-expense-list',
    resource: 'm_product',
    action: 'read',
  },
  {
    title: 'Diğer Giderler',
    icon: 'BoxIcon',
    route: 'other-expense-list',
    resource: 'm_product',
    action: 'read',
  },
  {
    title: 'Satışlar',
    icon: 'BoxIcon',
    route: 'p-sales-list',
    resource: 'm_import',
    action: 'read',
  },
  {
    title: 'Raporlar',
    icon: 'UserIcon',
    children: [
      {
        title: 'Satış Karlılık Raporu',
        route: 'report-sales',
      },
      {
        title: 'Tüm Gider ve Kazanç Raporu',
        route: 'earning-expense',
      },
      {
        title: 'İşlem Görmeyen Alış & Satış',
        route: 'non-processed',
      },
      {
        title: 'Mevcut Stok',
        route: 'stock-reports',
      },
      {
        title: 'Stok Geçmişi',
        route: 'stock-reports-date-based',
      },
    ],
  },
]
